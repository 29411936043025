import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SocialLinks = makeShortcode("SocialLinks");
const FontAwesomeIcon = makeShortcode("FontAwesomeIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      textAlign: 'center'
    }}>
      <SocialLinks justifyContent='center' fontSize={40} mdxType="SocialLinks" />
      <div><FontAwesomeIcon icon='heart' mdxType="FontAwesomeIcon" /> our blog buy us a <a href="https://ko-fi.com/teamyayin"><FontAwesomeIcon icon='coffee' mdxType="FontAwesomeIcon" /></a></div>
      <div>Powered by <a href="https://gatsbyjs.org/" target="_blank" rel="noopener noreffer" title="GatsbyJS"><FontAwesomeIcon icon={['fab', 'react']} mdxType="FontAwesomeIcon" /></a> and Chronoblog a Gatsby starter.</div>
      <div>© {new Date().getFullYear()} <a href="https://www.yayinailab.tech" target="_blank">Yayin AI Labs</a></div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      